<template>
  <div>
    <div v-for="item in customerList" :key="item.userId" :name="item.userId" :title="item.displayName" class="lemon-contact" @click="onChangeContact(item)">
      <span class="lemon-badge lemon-contact__avatar">
        <span class="lemon-avatar lemon-avatar--circle" style="width: 40px; height: 40px; line-height: 40px; font-size: 20px;">
              <img :src="item.avatar" />
          </span>
        <span class="lemon-badge__label" v-if="item.unread > 0">{{item.unread}}</span>
      </span>
      <div class="lemon-contact__inner">
        <p class="lemon-contact__label">
          <span class="lemon-contact__name">{{item.displayName}}</span>
          <span class="lemon-contact__time">{{formatTime(item.lastSendTime)}}</span>
        </p>
        <p class="lemon-contact__content">
          <span style="margin-left: 5px;">标识：{{item.id}}</span>
        </p>
        <p class="lemon-contact__content">
          <van-tag type="primary" v-if="isSelf(item.id)">本人</van-tag>
          <van-tag type="success" v-show="isSelf(item.id) || item.online" style="margin-left: 2px">在线</van-tag>
          <span style="margin-left: 5px;">消息：{{item.lastContent}}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import * as IMApi from "@/api/admin/im";
import {curUserId} from '@/utils/admin/auth'
import {timeFormat} from '@/utils'
import {Tag} from "vant";


const getTime = () => new Date().getTime();

export default {
  name: "webSocketContact",
  components: {
    [Tag.name]: Tag,
  },
  data() {
    return {
      contactList: [],
    };
  },
  computed: {
    // 监听全局socket消息状态
    ...mapState({
      customerList: state => state.im.customerList,
      refreshContactAction: state => state.im.refreshContactAction,
    }),
    formatTime() {
      return function(val) {
        return timeFormat(val);
      };
    },
    isSelf() {
      return function(val) {
        return curUserId() == val
      }
    }
  },
  mounted() {
    console.log('mounted')
  },
  watch: {
    // 监听接收socket消息
    refreshContactAction(val) {
      console.log('更新管理员联系人列表', val)
      IMApi.getContactListForAdmin(curUserId()).then(res => {
        console.log('updateMessage', res)
        console.log('mounted WebsocketGreetings initContacts', res)
        this.$store.commit('setCustomerList', res.data);
      })
    }
  },
  created() {
  },
  methods: {
    onChangeContact(user) {
      console.log('onChangeContact', user)
      this.$store.commit('setSelectChatUserId', user.id);
    }
  },
};
</script>
<style lang="less">

</style>
