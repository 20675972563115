<template>
  <div>
    <!--    <audio id="chatAudio">-->
    <!-- <source src="./assets/voice/notify.ogg" type="audio/ogg"> -->
    <!--        <source src="../assets/voice/notify.mp3" type="audio/mpeg">-->
    <!-- <source src="./assets/voice/notify.wav" type="audio/wav"> -->
    <!--    </audio>-->
  </div>
</template>

<script>
import Vue from 'vue'
// import {bindClientIdAPI,offlineAPI} from "@/api/login";
import {getImChatUser} from '@/utils/admin/auth'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import $store from "@/store";

export default {
  name: "socket",
  data() {
    return {
      websocket: null,
      userId: null,
      data: 0,
      timeout: 28 * 1000,//30秒一次心跳
      timeoutObj: null,//心跳心跳倒计时
      serverTimeoutObj: null,//心跳倒计时
      timeOutNum: null,//断开 重连倒计时
      stompClient: '',
      timer: '',
    }
  },
  methods: {
    getImChatUserId() {
      console.log('socket getImChatUserId be')
      const user = getImChatUser()
      console.log('socket getImChatUserId', user)
      this.userId = user.id
    },
    getWsUrl() {
      console.log('socket userId', this.userId)
      let WS_URI = process.env.VUE_APP_WEB_SOCKET_URL + this.userId
      console.log('socket userId WS_URI', WS_URI)
      return WS_URI;
    },
    initWebSocket() { //初始化weosocket
      //ws地址
      const WS_URI = this.getWsUrl();
      this.websocket = new WebSocket(WS_URI,  ["caixia-token"]);
      this.websocket.onopen = this.websocketOnOpen
      this.websocket.onmessage = this.websocketOnMessage;
      this.websocket.onclose = this.websocketClose;
      this.websocket.onerror = this.websocketOnError

      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = this.onbeforeunload

      Vue.prototype.$websocket = this.websocket;
    },
    start() { // 开启心跳
      // console.log('this.websocket.start')
      this.timeoutObj && clearTimeout(this.timeoutObj);
      this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
      this.timeoutObj = setTimeout(() => {
        // 这里发送一个心跳，后端收到后，返回一个心跳消息，
        // console.log('this.websocket.readyState', this.websocket.readyState)
        if (this.websocket && this.websocket.readyState == 1) { // 如果连接正常
          // console.log('this.websocket.start websocketSend')
          this.websocketSend({type: "PING"});
        } else { // 否则重连
          this.reconnect();
        }
        this.serverTimeoutObj = setTimeout(() => {
          //超时关闭
          this.websocket.close();
        }, this.timeout);

      }, this.timeout)
    },
    reset() { // 重置心跳
      // console.log('this.websocket.start reset')
      // 清除时间
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      // 重启心跳
      this.start();
    },
    //websocket发送消息
    webSocketSendPlain(message) {
      this.websocket.send(message)
    },
    onbeforeunload() {
      console.log('this.websocket.start onbeforeunload')
      this.closeWebSocket();
      // 关闭stomp链接
      this.disconnect();
      clearInterval(this.timer);
    },
    closeWebSocket() { // 关闭websocket
      this.websocket.close()
    },
    reconnect() { // 重新连接
      console.log('this.websocket.start reconnect')

      if (this.lockReconnect) return;
      this.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      this.timeoutnum && clearTimeout(this.timeoutnum);
      this.timeoutnum = setTimeout(() => {
        //新连接
        this.initWebSocket();
        this.lockReconnect = false;
      }, 5000);
    },
    websocketOnOpen() { //数据接收
      console.log('链路建立中')
      //开启心跳
      this.start();
      console.log("WebSocket连接成功" + '   状态码：' + this.websocket.readyState)
    },
    websocketOnError(e) { //数据接收
      console.log('链路异常', e)
      // 重连
      this.reconnect();
      console.log("WebSocket连接发生错误" + '   状态码：' + this.websocket.readyState)
    },
    websocketOnMessage(e) { //数据接收
      const data = JSON.parse(e.data);
      switch (data['type']) {
          // 服务端ping客户端
        case 'PING':
          //收到服务器信息，心跳重置
          this.reset();
          break;
        case 'ping':
          this.websocketSend({type: "pong"});
          break;
          // 登录 更新用户列表
        case 'init':
          // Lockr.set('client_id',data['client_id']);
          // bindClientIdAPI({client_id: data['client_id'],user_id:userInfo.user_id}).then(res=>{
          //     this.websocketSend({type:"bindUid",user_id:userInfo.user_id});
          //     console.log(data['client_id'],'消息服务启动成功');
          // }).catch(error => {
          //     console.log('连接失败');
          // })
          break;
        default:
          console.log('接收到消息', e.data)
          this.$store.commit('catchSocketAction', data);
          console.log(this.$store)
          break;
      }
    },
    websocketSend(agentData) {//数据发送
      const data = JSON.stringify(agentData);
      // console.log('this.websocket.start websocketSend', data)
      this.websocket.send(data);
    },
    websocketClose() {  //关闭
      console.log('链路关闭... ');
      // 重连
      this.reconnect();
      console.log("WebSocket连接关闭" + '   状态码：' + this.websocket.readyState)
    },
    playAudio() {
      const audio = document.getElementById('chatAudio');
      // 从头播放
      audio.currentTime = 0;
      audio.play();
    },

    // 开启SocketStompJs
    initWebSocketStompJs() {
      console.log('initWebSocketStompJs')
      this.connection();
      let that = this;
      // 断开重连机制,尝试发送消息,捕获异常发生时重连
      this.timer = setInterval(() => {
        try {
          that.stompClient.send("test");
        } catch (err) {
          console.log("断线了: " + err);
          that.connection();
        }
      }, 5000);
    },
    connection() {
      const url = process.env.VUE_APP_WEB_SOCKET_STOMP_URL
      console.log('initWebSocketStompJs url', url)
      // 建立连接对象
      let socket = new SockJS(url);
      // 获取STOMP子协议的客户端对象
      this.stompClient = Stomp.over(socket);
      // 定义客户端的认证信息,按需求配置
      let headers = {
        Authorization: ''
      }
      const _this = this
      // 向服务器发起websocket连接
      this.stompClient.connect(headers, () => {
        this.stompClient.send("/message/chat.sendMessage", {},
            JSON.stringify({type: 'JOIN'}),
        )
        // 订阅 更新联系人
        this.stompClient.subscribe('/topic/kf-update-contact-for-admin', (msg) => { // 订阅服务端提供的某个topic
          console.log('广播成功 订阅 更新联系人', msg)
          const body = JSON.parse(msg.body)
          console.log('广播成功 订阅 更新联系人 序列化后 body', body)
          let updateI;
          switch (body.type) {
            case "LINE_STATUS":
              updateI = {
                id: body.contactDto.id,
                online: body.contactDto.online,
              }
              _this.$store.dispatch("doLineStatus", {data: updateI})
              break
            case "LAST_MES_CONTACT":
              updateI = {
                id: body.contactDto.id,
                lastContent: body.contactDto.lastContent,
                lastSendTime: body.contactDto.lastSendTime,
              }
              _this.$store.dispatch("doLastMessContact", {data: updateI})
              break
            case "ADD_CONTACT":
              _this.$store.dispatch("doAddContact", {data: body.contactDto})
              break
            case "REMOVE_CONTACT":
              _this.$store.commit('setWatchRemoveContact', body.contactDto)
              break
            case 'MESSAGE_READ_BATCH':
              console.log('广播成功 订阅 更新联系人 序列化后 body payload', body.payload)
              _this.$store.dispatch("doMessageRead", {data: JSON.parse(body.payload)})
              break
          }
        }, headers);
      }, (err) => {
        // 连接发生错误时的处理函数
        console.log('失败')
        console.log(err);
      });
    },    //连接 后台
    disconnect() {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
    },  // 断开连接
  },
  created() {

  },
  mounted() {
    console.log('socket 开始初始化')
    this.getImChatUserId()
    this.initWebSocket()
    this.initWebSocketStompJs();
  },
  beforeDestroy: function () {
    console.log('this.websocket beforeDestroy')
    // 页面离开时断开连接,清除定时器
    this.onbeforeunload()
  }
}
</script>
