<template>
  <div>
    <div class="cc-1fkh" style="max-width: 600px; width: 100%;">
      <div class="cc-1f22 cc-qvio" data-mode="chat" data-helpdesk-collapsed="false" data-overflow="false"
           data-tile="shapes">
      <span class="cc-1vdv">
          <span class="cc-of6q cc-12kx"></span>
          <van-button round size="small" color="#C49700" icon="chat" style="margin-right: 3px">谈话</van-button>
          <van-button round size="small" color="#E1AE01" icon="shop" style="margin-left: 3px" @click="toCart">商品列表</van-button>
      </span>
        <div class="cc-m35l">
        <span class="cc-1hfo">
          <div v-if="!isLogin">
            <van-badge :content="item.unread" :dot="(item.unread === undefined || item.unread === null) && item.online"
                       color="#4ece3d" v-for="item in customerList" :key="item.id" :name="item.id">
              <van-image
                  round
                  width="50"
                  height="50"
                  :src="item.avatar"
                  style="position: relative; margin-left: -9px;"
                  @click="openChat(item.id)"
              />
            </van-badge>
          </div>
          <van-icon v-if="isLogin" name="friends-o" @click="toContactForAdmin()" size="40"/>
          <span class="cc-1arw"></span>
        </span>
          <span class="cc-gxox">
          <span class="cc-1h9x"><span class="cc-1esy cc-43er cc-yfb7">有疑问吗？联系我们！</span><span class="cc-1wh4"><span
              data-type="online" class="cc-1bia cc-43er">
          <span class="cc-lbs1 cc-43er cc-vwsc">一小时内回复 (9:00-21:00 CST)</span></span></span></span></span><!--v-if-->
        </div>
        <span class="cc-1wec cc-12vm">
        <span class="cc-1v31 cc-988o">
          <span class="cc-o77w cc-12kx"></span>
        </span>
      </span>
      </div>
    </div>
    <lemon-imui
        :style="'max-width: 600px; width: 100%;height:' + fullHeight + 'px'"
        simple
        :user="user"
        ref="IMUI"
        :sendKey="setSendKey"
        :wrapKey="wrapKey"
        @change-contact="handleChangeContact"
        @pull-messages="handlePullMessages"
        @message-click="handleMessageClick"
        @send="handleSend"
    >
      <template #cover>
        <div class="cover">
          <span><b>才下眉头</b> 客服</span>
        </div>
      </template>
      <template #message-after="message">
          <span v-if="message.fromUser.id === user.id" style="visibility: visible">
            <span v-if="!message.isRead"> 未读 </span>
            <span v-if="message.isRead" style="color: green"> 已读 </span>
          </span>
      </template>
      <template #editor-footer>
          使用键盘确认键发送消息
      </template>
    </lemon-imui>
    <Socket ref="socket"></Socket>
    <van-popup v-model="contactShow" :lazy-render="false" position="right" :style="{ width: '70%', height: '100%' }">
      <Contact ref="webSocketContact"/>
    </van-popup>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Socket from "@/components/socket";
import Contact from "@/view/websocket/contact"
import * as IMApi from "@/api/admin/im";
import {generateRandId} from "@/utils"
import {getImChatUser, isLogin, curUserId} from '@/utils/admin/auth'
const getTime = () => new Date().getTime();
import {Toast} from "vant";

Array.prototype.clone = function () {
  return [].concat(this);
}
export default {
  name: "websocketGreetings",
  components: {
    Socket,
    Contact
  },
  data() {
    return {
      // 当前用户信息
      user: {},
      received_messages: [],
      send_message: null,
      connected: false,
      stompClient: null,
      webSocket: null,
      pageSize: 1,
      listRows: 10,
      contactShow: false,
      isLogin: false,
      fullHeight: '',
      groupId: null,
      displayName: "",
      oldName: "",
      drawer: false,
      // 当前聊天
      currentChat: {},
      // 当前消息
      currentMessage: {},
      isGroup: 0,
      // 公告
      notice: '',
      // 设置
      setting: {
        theme: "blue",
        hideMessageName: false,
        hideMessageTime: false,
        avatarCricle: true,
        sendKey: 1,
        isVoice: true
      },
    };
  },
  computed: {
    // 监听全局socket消息状态
    ...mapState({
      socketAction: state => state.im.socketAction,
      customerList: state => state.im.customerList,
      selectChatUserId: state => state.im.selectChatUserId,
      refreshContactList: state => state.im.refreshContactList,
      watchUpdateContact: state => state.im.watchUpdateContact,
      watchAddContact: state => state.im.watchAddContact,
      watchRemoveContact: state => state.im.watchRemoveContact,
      readMessageIdList: state => state.im.readMessageIdList
    }),
    formatTime() {
      return function (val) {
        return timeFormat(val);
      };
    }
  },
  mounted() {
    this.fullHeight = document.documentElement.clientHeight - 170
    console.log('mounted WebsocketGreetings', this.fullHeight)
    const {IMUI} = this.$refs;
    const messageParam = {
      groupType: '111'
    }
    if (isLogin()) {
      IMApi.getContactListForAdmin(curUserId()).then(res => {
        console.log('updateMessage', res)
        const initContacts = [];
        res.data.forEach(i => {
          initContacts.push(i)
        })
        console.log('mounted WebsocketGreetings initContacts', initContacts)
        this.$store.commit('setCustomerList', res.data);
        IMUI.initContacts(initContacts);
        return res.data[0].id
      }).then((id) => {
        console.log('mounted WebsocketGreetings changeContact', id)
        IMUI.changeContact(id);
      })
    } else {
      IMApi.groupUserListAPI(messageParam).then(res => {
        console.log('updateMessage', res)
        const initContacts = [];
        res.data.forEach(i => {
          initContacts.push(i)
        })
        this.$store.commit('setCustomerList', res.data);
        IMUI.initContacts(initContacts);
        return res.data[0].id
      }).then((id) => {
        IMUI.changeContact(id);
      })
    }

  },
  watch: {
    refreshContactList(val) {
      console.log('watch refreshContactList', val)
      const {IMUI} = this.$refs;
      const oldContacts = IMUI.getContacts()
      console.log('watch refreshContactList oldContacts', oldContacts)
      val.forEach(i => {
        console.log('watch refreshContactList appendContact', i)
        IMUI.appendContact(i)
      })
    },
    selectChatUserId(val) {
      console.log('watch selectChatUserId', val)
      const {IMUI} = this.$refs;
      if (val === undefined || val === null) {
        return
      }
      IMUI.changeContact(val)
    },
    watchUpdateContact(val) {
      // 更新联系人 上线、下线、最近一条消息
      console.log('watch watchUpdateContact', val)
      console.log('watch watchUpdateContact', val.id)
      const {IMUI} = this.$refs;
      if (val === undefined || val === null) {
        return
      }
      IMUI.updateContact(val)
    },
    watchAddContact(val) {
      // 增加联系人
      console.log('watch watchAddContact', val)
      const {IMUI} = this.$refs;
      if (val === undefined || val === null) {
        return
      }
      IMUI.appendContact(val)
    },
    watchRemoveContact(val) {
      // 移除联系人
      console.log('watch watchRemoveContact', val)
      const {IMUI} = this.$refs;
      if (val === undefined || val === null) {
        return
      }
      IMUI.removeContact(val.id)
    },
    readMessageIdList(val) {
      this.setLocalMsgIsRead(val)
    },
    socketAction(val) {
      // 监听接收socket消息
      console.log('watch socketAction', val)
      let message = val.content;
      const {IMUI} = this.$refs;
      switch (val.type) {
          //上线、下线通知
        case "isOnline":
          IMUI.updateContact({
            id: message.id,
            is_online: message.is_online
          })
          break;
        case "offline":
          if (message.id == this.user.id && message.client_id != client_id && !message.isMobile) {
            this.$message.error = "您的账号在其他地方登录，已被迫下线！";
            this.$store.dispatch("LogOut").then(() => {
              this.$router.push({path: "/login"});
            });
          }
          break;
          // 接收消息
        case "SIMPLE":
        case "group":
          console.log('SIMPLE SIMPLE', message)
          // 如果是自己发送的消息则不需要提示
          if (message.fromUser.id !== this.user.id) {
            console.log('发送者不是自己', message.fromUser.id, this.user.id)
            // const contact = this.getContact(message.toContactId);
            // // 如果开启了声音才播放
            // if (this.setting.isVoice && contact.is_notice === 1) {
            //   this.popNotice(message);
            // }
          }
          this.receiveMsg(message);
          break;
        case 'COMPENSATION':
          console.log('COMPENSATION', message)
          this.receiveCompensationMsg(message)
          break
          // 撤回消息
        case "undoMessage":
          IMUI.updateMessage(message);
          break;
          //处理消息已读,将本地的未读消息修改为已读状态
        case "READ":
          console.log('watch socketAction READ', val.payload)
          this.setLocalMsgIsRead(val.payload);
          break;
        // 发布公告
        case "setNotice":
          IMUI.updateContact({
            id: message.group_id,
            notice: message.notice
          });
          // 发布事件消息
          IMUI.appendMessage(
              {
                id: generateRandId(),
                type: "event",
                //使用 jsx 时 click必须使用箭头函数（使上下文停留在vue内）
                content: (
                    <div>
                    <span> 管理员 发布了公告： {message.notice}</span>
                    </div>
                ),
                toContactId: message.group_id,
                sendTime: getTime()
              }, true);
          break;
      }
    }
  },
  created() {
    // loginForPage()
    const saveUser = getImChatUser()
    console.log('saveUser', saveUser)
    this.user = saveUser
    console.log('isLogin()', isLogin())
    this.isLogin = isLogin()
  },
  methods: {
    // 设置发送键
    setSendKey(e) {
      return this.setting.sendKey === 1 ? (e.keyCode == 13 && !e.ctrlKey && !e.shiftKey) : (e.keyCode == 13 && e.ctrlKey);
    },
    wrapKey(e){
      return this.setting.sendKey === 1 ? (e.keyCode == 13 && e.ctrlKey) : (e.keyCode == 13 && !e.ctrlKey && !e.shiftKey);
    },
    // 打开聊天窗口
    openChat(contactId) {
      console.log('切换聊天窗口', contactId)
      const {IMUI} = this.$refs
      console.log('切换聊天窗口', IMUI.getContacts())
      this.pageSize = 1;
      // this.keywords = "";
      IMUI.changeContact(contactId)
    },
    handleMessageClick(e, key, message) {
      const {IMUI} = this.$refs;
      if (key == "status" && message.status === "failed") {
        IMUI.updateMessage({
          id: message.id,
          toContactId: message.toContactId,
          status: "going",
          content: "重新发送消息...",
        });
        setTimeout(() => {
          IMUI.updateMessage({
            id: message.id,
            toContactId: message.toContactId,
            status: "failed",
            content: "还是发送失败",
          });
        }, 2000);
      }
    },
    sendFile(message, next, file) {
      // 判断文件如果大于5M就删除该聊天消息
      if (file.size > 5242880) {
        const {IMUI} = this.$refs;
        IMUI.removeMessage(message.id);
        Toast('上传的内容不能大于5MB！');
        return
      }
      const _vm = this
      let formData = new FormData();
      formData.append("file", file);
      IMApi.sendFileAPI(formData)
          .then(res => {
            if (res.status === 200) {
              _vm.sendMessage(message, next, res.data)
            } else {
              next({status: "failed"});
            }
          })
          .catch(error => {
            if (error.code === 401) {//已开启禁言
              IMUI.removeMessage(message.id);
            } else {
              next({status: "failed"});
            }
          });
    },
    sendMessage(message, next, fileInfo) {
      console.log("发送消息到服务端", message, fileInfo)
      const {IMUI} = this.$refs;
      const messageParam = {
        id: message.id,
        content: fileInfo.fileKey,
        fromUser: message.fromUser,
        sendTime: message.sendTime,
        status: message.status,
        toContactId: message.toContactId,
        type: message.type,
        isRead: 0,
        isGroup: 0,
        fileSize: fileInfo.fileSize,
        fileName: fileInfo.fileName
      }
      console.log('messageParam', messageParam)
      IMApi.sendMessageAPI(messageParam)
          .then(res => {
            console.log('updateMessage', res)
            IMUI.setEditorValue("");
            IMUI.updateMessage(res);
            next();
          })
          .catch(error => {
            if (error.code === 401) {//已开启禁言
              IMUI.removeMessage(message.id);
            } else {
              next({status: "failed"});
            }
          });
    },
    handleSend(message, next, file) {
      console.log('handleSend', message)
      message.isGroup = this.isGroup;
      const {IMUI} = this.$refs;
      // 如果是文件选择文件上传接口
      if (file) {
        this.sendFile(message, next, file)
      } else {
        if (message.content.length > 3000) {
          Toast("内容过长，不能超过3000个字符")
          IMUI.removeMessage(message.id)
          return
        }
        const messageParam = {
          id: message.id,
          content: message.content,
          fromUser: message.fromUser,
          sendTime: message.sendTime,
          status: message.status,
          toContactId: message.toContactId,
          type: message.type,
          isRead: 0,
          isGroup: 0
        }
        console.log('messageParam', messageParam)
        IMApi.sendMessageAPI(messageParam)
            .then(res => {
              console.log('updateMessage', res)
              IMUI.setEditorValue("");
              IMUI.updateMessage(res);
              next();
            })
            .catch(error => {
              if (error.code === 401) {//已开启禁言
                IMUI.removeMessage(message.id);
              } else {
                next({status: "failed"});
              }
            });
      }
    },
    // 切换聊天窗口时要检测那些消息未读
    handleChangeContact(contact, instance) {
      console.log('handleChangeContact', contact, instance)
      instance.updateContact({
        id: contact.id,
        unread: 0
      })
      // 设置改联系人未读数量为 null
      this.$store.dispatch("doNullReadNum", {userId: contact.id})
      // 聊天记录列表恢复到最初第一页
      this.pageSize = 1;
      this.displayName = contact.displayName;
      this.oldName = contact.displayName;
      this.currentChat = contact;
      // 如果是群聊，拉取群成员列表，如果刚才拉取过，现在就不用拉取了
      if (contact.isGroup === 1 && this.groupId !== contact.id) {
        this.getGroupUserList(contact.id);
      }
      //切换聊天后全局设置是否是群聊或者单聊
      this.isGroup = contact.isGroup;
      // 如果是团队id，保存当前团队id避免下次切换回来的时候重复请求成员列表
      if (this.isGroup === 1) {
        this.groupId = contact.id;
        this.notice = contact.notice;
      }
      let data = [];
      const {IMUI} = this.$refs;
      // 获取当前聊天窗口的所有消息
      const messages = IMUI.getMessages(contact.id);
      console.log('handleChangeContact messages', contact.id, messages)

      for (let i = 0; messages.length > i; i++) {
        if (
            messages[i].isRead === 0 &&
            messages[i].fromUser.id !== this.user.id
        ) {
          data.push(messages[i]);
        }
      }
      // 如果有未读的消息，需要将消息修改为已读
      console.log('handleChangeContact messages setMsgIsReadAPI', data)
      this.doReadMsg(data, contact.id, contact.id, contact.isGroup)
      instance.closeDrawer();
    },
    doReadMsg(data, toContactId, fromUserId, isGroup) {
      if (data.length < 1) {
       return
      }
      IMApi.setMsgIsReadAPI({
        isGroup: isGroup,
        toContactId: toContactId,
        messages: data,
        fromUserId: fromUserId
      }).then(res => {
        if (res.status === 200) {
          this.setLocalMsgIsRead(data);
        }
      });
    },
    doReadMsgForPullMessage(data, toContactId, fromUserId, isGroup) {
      console.log('doReadMsgForPullMessage 拉取当前联系人聊天记录 发送已读 data, toContactId, fromUserId, isGroup', data, toContactId, fromUserId, isGroup)
      if (data.length < 1) {
        return
      }
      let reqData = [];
      for (let i = 0; data.length > i; i++) {
        if (
            data[i].isRead === 0 &&
            data[i].fromUser.id !== this.user.id
        ) {
          reqData.push(data[i]);
        }
      }
      console.log('doReadMsgForPullMessage reqData', reqData)
      this.doReadMsg(reqData, toContactId, fromUserId, isGroup)
    },
    // 将本地消息设置为已读
    setLocalMsgIsRead(message) {
      const {IMUI} = this.$refs;
      for (let i = 0; message.length > i; i++) {
        const data = {
          id: message[i]["id"],
          isRead: 1,
          status: "succeed",
          content: message[i]["content"] + " "
        };
        console.log('setLocalMsgIsRead data', data)
        IMUI.updateMessage(data);
      }
    },
    // 拉取聊天记录
    handlePullMessages(contact, next, instance) {
      console.log('handlePullMessages', contact)
      const _vm = this
      IMApi.getMessageListAPI({
        userId: this.user.id,
        toContactId: contact.id,
        isGroup: contact.isGroup,
        pageSize: this.pageSize,
        listRows: this.listRows
      }).then(res => {
        this.pageSize++;
        let isEnd = false;
        let messages = res.data;
        if (messages.length < this.listRows) {
          isEnd = true;
        }
        _vm.doReadMsgForPullMessage(messages, contact.id, contact.id, contact.isGroup)
        next(messages, isEnd);
      }).catch(error => {
        next([], true);
      });
      return true;
    },
    // 接收消息重新渲染
    receiveMsg(message) {
      const {IMUI} = this.$refs;
      const contact = IMUI.getCurrentContact();
      console.log('receiveMsg contact', contact)
      console.log('receiveMsg message', message)
      console.log('receiveMsg user', this.user)
      // 如果收到消息是当前窗口的聊天，需要将消息修改为已读
      if (contact.id === message.toContactId) {
        console.log('receiveMsg contact 已读', contact.id, message.toContactId)
        IMApi.setMsgIsReadAPI({
          isGroup: contact.isGroup,
          toContactId: contact.id,
          messages: [message],
          fromUserId: contact.id
        })
      } else {
        // 处理未读联系人消息数量
        this.$store.dispatch("doAddNoReadNum", {userId: message.toContactId})
      }
      // 更新联系人列表最新消息与时间
      let updateI = {
        id: message.toContactId,
        lastContent: message.content,
        lastSendTime: message.sendTime,
      }
      this.$store.dispatch("doLastMessContact", {data: updateI})
      if (this.user.id === message.toContactId) {
        // 如果是自己发给自己的信息则需要替换一下 fromUserId 让这条信息在消息列表的左边
        message.fromUser.id = 'oneself'
      }
      IMUI.appendMessage(message, true);
    },
    // 接收到发送者补偿消息 多端
    receiveCompensationMsg(message) {
      const {IMUI} = this.$refs;
      IMUI.appendMessage(message, true);
    },
    toContactForAdmin() {
      this.contactShow = true
    },
    toCart() {
      this.$router.push({path: '/cart'})
    }
  },
};
</script>
<style lang="less">
//@badge-padding 90px;
@badge-padding: -10 -10px;
@badge-dot-size: 20px;
.van-badge--fixed {
  position: absolute;
  top: 4px;
  right: 7px;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-transform-origin: 100%;
  transform-origin: 100%;
}
.van-badge--fixed {
  position: absolute;
  top: 1px;
  right: 6px;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-transform-origin: 100%;
  transform-origin: 100%;
}

.cover {
  text-align: center;
  user-select: none;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  i {
    font-size: 84px;
    color: #e6e6e6;
  }

  p {
    font-size: 18px;
    color: #ddd;
    line-height: 50px;
  }
}

.cover-text1 {
  display: flex;
  display: -webkit-flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

cc-1opo ::selection {
  color: #1c293b !important;
  background-color: #FFF0C1 !important;
}

.cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-1iar {
  text-align: center !important;
  display: block !important;
}

.cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-lbs1 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-gs7o {
  font-size: 12.4px !important;
  line-height: 18px !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-lbs1 {
  letter-spacing: .2px !important;
  white-space: nowrap !important;
}

.cc-43er {
  color: #FFFFFF !important;
}

.cc-yfb7 * {
  font-family: "Crisp Noto Sans Bold", sans-serif !important;
}

.cc-1fkh1 *, .cc-1m7s ::after, .cc-1m7s ::before {
  background-attachment: scroll !important;
  background-color: transparent !important;
  background-image: none !important;
  background-position: 0 0 !important;
  background-repeat: repeat !important;
  border-color: currentcolor !important;
  border-style: none !important;
  border-width: medium !important;
  bottom: auto !important;
  clear: none !important;
  clip: auto !important;
  color: inherit !important;
  counter-increment: none !important;
  counter-reset: none !important;
  cursor: auto !important;
  direction: inherit !important;
  display: inline !important;
  float: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  font-style: inherit !important;
  font-variant: normal !important;
  font-weight: inherit !important;
  height: auto !important;
  left: auto !important;
  letter-spacing: normal !important;
  line-height: inherit !important;
  list-style-type: inherit !important;
  list-style-position: outside !important;
  list-style-image: none !important;
  margin: 0 !important;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  outline: 0 !important;
  overflow: visible !important;
  padding: 0 !important;
  position: static !important;
  quotes: "" "" !important;
  right: auto !important;
  table-layout: auto !important;
  text-align: inherit !important;
  text-decoration: inherit !important;
  text-indent: 0 !important;
  text-transform: none !important;
  text-rendering: auto !important;
  top: auto !important;
  unicode-bidi: normal !important;
  vertical-align: baseline !important;
  visibility: inherit !important;
  white-space: normal !important;
  width: auto !important;
  word-spacing: normal !important;
  z-index: auto !important;
  background-origin: padding-box !important;
  background-clip: border-box !important;
  background-size: auto !important;
  border-image: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  box-sizing: content-box !important;
  column-count: auto !important;
  column-gap: normal !important;
  column-rule: medium none #000 !important;
  column-width: auto !important;
  column-span: 1 !important;
  -webkit-text-fill-color: currentcolor !important;
  -webkit-tap-highlight-color: transparent !important;
  -moz-tap-highlight-color: transparent !important;
  -ms-tap-highlight-color: transparent !important;
  -o-tap-highlight-color: transparent !important;
  font-feature-settings: normal !important;
  overflow-x: visible !important;
  overflow-y: visible !important;
  -webkit-hyphens: manual !important;
  -moz-hyphens: manual !important;
  -ms-hyphens: manual !important;
  hyphens: manual !important;
  -webkit-hyphenate-character: "‐" !important;
  hyphenate-character: "‐" !important;
  perspective: none !important;
  perspective-origin: 50% 50% !important;
  backface-visibility: visible !important;
  text-shadow: none !important;
  transition: all 0s ease 0s !important;
  word-break: normal !important;
}

.cc-1fkh .cc-1f22[data-tile="shapes"]::before {
  background-image: url(../images/tiles/shapes.svg?1f950c4) !important;
  background-size: 260px !important;
  opacity: .1 !important;
}

.cc-1fkh .cc-1f22[data-tile]::before {
  content: "" !important;
  background-repeat: repeat !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 0 !important;
}

.cc-1m7s video {
  display: block !important;
}

.cc-1fkh {
  position: relative !important;
  z-index: 5 !important;
}

.cc-1fkh .cc-1f22[data-mode="chat"] {
  height: 170px !important;
}

.cc-1fkh .cc-1f22[data-tile] {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .01) 0, rgba(0, 0, 0, .04) 99%) !important;
}

.cc-1fkh .cc-1f22 {
  text-align: center !important;
  position: relative !important;
  overflow: hidden !important;
  transition: height .2s ease-in-out !important;
}

.cc-1fkh * {
  cursor: pointer !important;
}

.cc-1fkh .cc-qvio {
  background-color: #E1AE01 !important;
}

.cc-1fkh .cc-1f22[data-tile]::before {
  content: "" !important;
  background-repeat: repeat !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 0 !important;
}

.cc-iv0q .cc-1ta8[data-chat-status="initial"] .cc-1fkh .cc-1f22[data-mode="chat"] .cc-1vdv {
  margin-bottom: -2px !important;
}

.cc-1fkh .cc-1f22 .cc-1vdv {
  text-align: center !important;
  list-style-type: none !important;
  padding: 10px 8px !important;
  display: flex !important;
  justify-content: center !important;
  position: relative !important;
  z-index: 1 !important;
}

.cc-1fkh .cc-1f22 .cc-1vdv .cc-rhko, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-1vdv .cc-rhko .cc-1aem {
  overflow: hidden !important;
}

.cc-1fkh .cc-1f22 .cc-1vdv .cc-rhko {
  line-height: 30px !important;
  pointer-events: auto !important;
  margin: 0 1px !important;
  padding: 0 30px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 12px !important;
}

.cc-1fkh .cc-1f22 .cc-1vdv .cc-rhko[data-mode="chat"] .cc-of6q {
  --mask-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE2IiB3aWR0aD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBkPSJNMTMuMDUzIDQuNTU0YzEuOTQxLjc4NSAzLjI4NyAyLjQ3MiAzLjI4NyA0LjQzMyAwIC45Mi0uMzAxIDEuNzc5LS44MTcgMi41MTV2Mi45NzNhLjUyNC41MjQgMCAwMS0uODM4LjQybC0xLjg0OS0xLjM4N2E2LjU0MyA2LjU0MyAwIDAxLTIuMjE1LjM4MWMtMi4xMTcgMC0zLjk2MS0uOTktNC45NS0yLjQ1NGwuMDQ4LjAwM2M0LjA1NSAwIDcuMzUzLTIuOTMyIDcuMzUzLTYuNTM2IDAtLjExNy0uMDEyLS4yMzItLjAxOS0uMzQ4ek01LjcxOSAwYzMuMTU5IDAgNS43MiAyLjE5NCA1LjcyIDQuOTAycy0yLjU2MSA0LjkwMi01LjcyIDQuOTAyYTYuNTQgNi41NCAwIDAxLTIuMjE1LS4zODFsLTEuODQ4IDEuMzg2YS41MjUuNTI1IDAgMDEtLjgzOS0uNDJWNy40MThDLjMwMSA2LjY4IDAgNS44MjMgMCA0LjkwMiAwIDIuMTk0IDIuNTYgMCA1LjcyIDB6Ii8+PC9jbGlwUGF0aD48ZyBjbGlwLXBhdGg9InVybCgjYSkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLjUpIj48cGF0aCBkPSJNMCAwaDE2LjM0djE1SDB6Ii8+PC9nPjwvc3ZnPg==) !important;
  -webkit-mask-image: var(--mask-image) !important;
  mask-image: var(--mask-image) !important;
}

.cc-1fkh .cc-1f22 .cc-1vdv .cc-rhko .cc-of6q {
  -webkit-mask-size: contain !important;
  mask-size: contain !important;
  -webkit-mask-repeat: no-repeat !important;
  mask-repeat: no-repeat !important;
  -webkit-mask-position: center !important;
  mask-position: center !important;
  display: inline-block !important;
  width: 15px !important;
  height: 15px !important;
  margin-left: -2px !important;
  margin-right: 10px !important;
  flex: 0 0 auto !important;
}

.cc-1fkh .cc-1f22 .cc-1vdv .cc-rhko .cc-1aem {
  font-size: 12px !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.cc-1fkh .cc-1f22 .cc-1vdv .cc-rhko, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-1vdv .cc-rhko .cc-1aem {
  overflow: hidden !important;
}

.cc-1fkh .cc-1f22 .cc-m35l {
  text-align: center !important;
  //pointer-events: none !important;
  padding: 0 6px !important;
  display: block !important;
  position: relative !important;
  z-index: 2 !important;
}

.cc-1fkh .cc-1f22 .cc-1hfo {
  text-align: center !important;
  margin-bottom: 7px !important;
  display: block !important;
}

.cc-1fkh .cc-1f22 .cc-1hfo {
  line-height: 0 !important;
}

.cc-1fkh .cc-1f22 .cc-gxox {
  text-align: left !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-1esy, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-1iar {
  text-align: center !important;
  display: block !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-1esy, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-1iar, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-lbs1 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-1esy, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-1iar, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-gs7o {
  font-size: 12.4px !important;
  line-height: 18px !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-1esy, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-gs7o, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-lbs1 {
  letter-spacing: .2px !important;
  white-space: nowrap !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-1wh4 {
  margin-top: 3px !important;
  display: block !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-1wh4 .cc-1bia, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-1wh4 .cc-1bia .cc-lbs1 {
  font-size: 11.6px !important;
  line-height: 16px !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-1wh4 .cc-1bia {
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-1wh4 .cc-1bia[data-type="online"]::before {
  content: "" !important;
  background-color: #4ece3d !important;
  vertical-align: middle !important;
  width: 8px !important;
  height: 8px !important;
  margin-right: 5px !important;
  flex: 0 0 auto !important;
  border-radius: 8px !important;
}

.cc-1m7s ::before {
  background-attachment: scroll !important;
  background-color: transparent !important;
  background-image: none !important;
  background-position: 0 0 !important;
  background-repeat: repeat !important;
  border-color: currentcolor !important;
  border-style: none !important;
  border-width: medium !important;
  bottom: auto !important;
  clear: none !important;
  clip: auto !important;
  color: inherit !important;
  counter-increment: none !important;
  counter-reset: none !important;
  cursor: auto !important;
  direction: inherit !important;
  display: inline !important;
  float: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
  font-style: inherit !important;
  font-variant: normal !important;
  font-weight: inherit !important;
  height: auto !important;
  left: auto !important;
  letter-spacing: normal !important;
  line-height: inherit !important;
  list-style-type: inherit !important;
  list-style-position: outside !important;
  list-style-image: none !important;
  margin: 0 !important;
  margin-right: 0px;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  outline: 0 !important;
  overflow: visible !important;
  padding: 0 !important;
  position: static !important;
  quotes: "" "" !important;
  right: auto !important;
  table-layout: auto !important;
  text-align: inherit !important;
  text-decoration: inherit !important;
  text-indent: 0 !important;
  text-transform: none !important;
  text-rendering: auto !important;
  top: auto !important;
  unicode-bidi: normal !important;
  vertical-align: baseline !important;
  visibility: inherit !important;
  white-space: normal !important;
  width: auto !important;
  word-spacing: normal !important;
  z-index: auto !important;
  background-origin: padding-box !important;
  background-clip: border-box !important;
  background-size: auto !important;
  border-image: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  box-sizing: content-box !important;
  column-count: auto !important;
  column-gap: normal !important;
  column-rule: medium none #000 !important;
  column-width: auto !important;
  column-span: 1 !important;
  -webkit-text-fill-color: currentcolor !important;
  -webkit-tap-highlight-color: transparent !important;
  -moz-tap-highlight-color: transparent !important;
  -ms-tap-highlight-color: transparent !important;
  -o-tap-highlight-color: transparent !important;
  font-feature-settings: normal !important;
  overflow-x: visible !important;
  overflow-y: visible !important;
  -webkit-hyphens: manual !important;
  -moz-hyphens: manual !important;
  -ms-hyphens: manual !important;
  hyphens: manual !important;
  -webkit-hyphenate-character: "‐" !important;
  hyphenate-character: "‐" !important;
  perspective: none !important;
  perspective-origin: 50% 50% !important;
  backface-visibility: visible !important;
  text-shadow: none !important;
  transition: all 0s ease 0s !important;
  word-break: normal !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-1wh4 .cc-1bia .cc-lbs1 {
  opacity: .75 !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-1wh4 .cc-1bia .cc-lbs1 {
  font-size: 11.6px !important;
  line-height: 16px !important;
}

.cc-1fkh .cc-1f22 .cc-gxox .cc-1esy, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-1iar, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-lbs1 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

cc-1fkh .cc-1f22 .cc-gxox .cc-1esy, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-gs7o, .crisp-client .cc-1m7s .cc-iv0q .cc-1ta8 .cc-1fkh .cc-1f22 .cc-gxox .cc-lbs1 {
  letter-spacing: .2px !important;
  white-space: nowrap !important;
}
</style>
